import React from 'react'
import { ContactFormServices } from '../Components/ContactFormServices'
import { useTranslation } from 'react-i18next'

function Discovery() {
  const [t,i18n] = useTranslation("global");
  return (
    <>
      <div className="container-fluid py-5 mb-5 wow fadeIn" style={{ background: "black" }}>
        <div className="container text-left py-5">
          <p className="mb-4 d-inline-block"><a className="text-secondary d-inline-block" href="/services">{t("navbar.services")}&nbsp;&nbsp;&nbsp;&nbsp;&gt;</a>
            <a className='d-inline-block text-white'>&nbsp;&nbsp;&nbsp;&nbsp; {t("discovery.desc")}</a>
          </p>
          <h1 className="display-2 text-white mb-4 ">{t("discovery.title")}</h1>
          <h4 className=" text-white mb-4 ">
          </h4>
        </div>
      </div>

      <div className='container-fluid py-4 mt-5 mb-3 '>
        <div className='container'>
          <div className='row align-items-center'>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
              <img src="/img/imagenes/discovery-1.webp" className="img-fluid" alt="imagen" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4 ">
              <h2><strong>{t("discovery.section1Subtitle")}</strong>
              </h2>
              <h5 className='mt-3'>
                {t("discovery.section1Content")}
              </h5>
              <div className="des_tag mt-3" style={{ fontSize: "14px" }}>
                <div className="col-md-5 " ><p className="service_tag">{t("discovery.section1Tags.tag1")}</p></div>
                <div className="col-md-5 "><p className="service_tag">{t("discovery.section1Tags.tag2")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("discovery.section1Tags.tag3")}</p></div>
                <div className="col-md-5 "><p className="service_tag">{t("discovery.section1Tags.tag4")}</p></div>
                <div className="col-md-5 "><p className="service_tag">{t("discovery.section1Tags.tag5")}</p></div>
                <div className="col-md-5 "><p className="service_tag">{t("discovery.section1Tags.tag6")}</p></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-3 mb-4 py-5  " style={{ backgroundColor: "#00FFFF" }}>
        <div className='container'>
          <div className='row'>
            <h1 className=" text-dark display-6 pb-5">
              {t("discovery.section2Title")}
            </h1>
            <h5>
              {t("discovery.section2Content")}
            </h5>
          </div>
        </div>
      </div>

      <div className='container-fluid py-4 mt-5 mb-3 '>
        <div className='container'>

          <div className='row align-items-center'>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
              <img src="/img/imagenes/discovery-2.webp" className="img-fluid" alt="imagen" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4 ">
              <h2>{t("discovery.section3Title")}
              </h2>
              <h5 className='mt-3'>
                {t("discovery.section3Content")}
              </h5>

            </div>

          </div>
        </div>
      </div>

      <div className='container-fluid py-4 my-3'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className="image-container col-xl-6 col-lg-6 col-md-6 col-sm-12 order-sm-2 ">
              <img src="/img/imagenes/discovery-3.webp" className="img-fluid" alt="imagen" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
              <h2>{t("discovery.section4Title")}</h2>
              <h5 className='mt-3'>
                {t("discovery.section4Content")}
              </h5>

            </div>

          </div>
        </div>
      </div>

      <div className='container-fluid py-4 my-3' id="software-development">
        <div className='container'>

          <div className='row align-items-center'>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
              <img src="/img/imagenes/discovery-4.webp" className="img-fluid" alt="imagen" />
            </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4">
            <h2>{t("discovery.section5Title")}
            </h2>
            <h5 className='mt-3'>
              {t("discovery.section5Content")}
            </h5>
            
          </div>
      
        </div>
      </div>
    </div>

    <div className='container-fluid py-4 my-3'>
  <div className='container'>
    <div className='row align-items-center'>
    <div className="image-container col-xl-6 col-lg-6 col-md-6 col-sm-12 order-sm-2 ">
        <img src="/img/imagenes/discovery-5.webp" className="img-fluid" alt="imagen" />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
        <h2>{t("discovery.section6Title")}</h2>
        <h5 className='mt-3'>
          {t("discovery.section6Content")}
        </h5>
      </div>
      
    </div>
  </div>
</div>
<div className="container-fluid py-3 my-3 " id="form-discount" >
           <div className="row g-5 justify-content-center form-discount"  >
                <ContactFormServices/>
            </div>
        </div>
    </>

  )
}

export  {Discovery}
