import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { TestimonialCard } from './TestimonialCard';
import { useTranslation } from 'react-i18next'

 
    
function TestimonalHome() {
  const [t] = useTranslation("global");
  const testimonials = [
    {
      name: t('testimonials.0.name'),
      description: t('testimonials.0.description'),
      job: t('testimonials.0.job'),
      img: 'img/nicolas.png',
    },
    {
      name: t('testimonials.1.name'),
      description: t('testimonials.1.description'),
      job: t('testimonials.1.job'),
      img: 'img/anita.png',
    },
    {
      name: t('testimonials.2.name'),
      description: t('testimonials.2.description'),
      job: t('testimonials.2.job'),
      img: 'img/zara.png',
    },
    {
      name: t('testimonials.3.name'),
      description: t('testimonials.3.description'),
      job: t('testimonials.3.job'),
      img: 'img/tomas2.png',
    },
  ]
  return (
    <div className="container-xxl pt-5">
        <div className="container">
            <div className="text-center text-md-start pb-5 pb-md-0 wow fadeInUp" data-wow-delay="0.1s"
                style={{maxWidth: "500px"}}>
                <p className="fs-5 fw-medium text-primary">{t("testimonialTitle")}</p>
                <h1 className="display-5 mb-5">{t("testimonialSubtitle")}</h1>
            </div>
            
            <OwlCarousel className="owl-carousel testimonial-carousel wow fadeInUp" autoplay={true} center loop margin={10} nav
            responsive={{
                0:{
                    items:1
                },
                600:{
                    items:2
                },
                1000:{
                    items:3
                }}}>
                {               
                    testimonials.map(testimonialCard => {
                        return (
                            <TestimonialCard
                                description={testimonialCard.description}
                                name={testimonialCard.name}
                                job={testimonialCard.job}
                                img={testimonialCard.img}
                            />

                        )})
                }
            </OwlCarousel>
            
        </div>
    </div>
  )
}

export {TestimonalHome}
