import React from 'react'

function ServiceContent({title,description}) {

    
  return (

      <div class="row justify-content-center mb-5 service-content" id={title.toLowerCase().replace(/[\s/'&]+/g, "-")}>
                  <div class="col-md-3 text-md-end">
                    <h4 class="mx-md-3">{title}</h4>
                  </div>
                  <div class="col-md-5">
                    <p>{description}</p>
                    </div>
      </div>
  
  )
}

export {ServiceContent}
