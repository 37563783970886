import React from 'react'
import { CallToAction } from '../Components/CallToAction'
import { useTranslation } from 'react-i18next'


function About() {
const [t] = useTranslation("global");
  return (
    <React.Fragment>
        <div className="container-fluid py-5 mb-5 wow fadeIn bg-primary" data-wow-delay="0.1s">
        <div className="container text-center py-5">
            <h1 className="display-2 text-white mb-4 animated slideInDown">{t("aboutUs.title")}</h1>
        </div>
        </div>
        <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
            <p className="fs-5 fw-medium text-primary">{t("aboutUs.team")}</p>
            <h1 className="display-5 mb-5">{t("aboutUs.header")}</h1>
            </div>
            <div className="container justify-content-center">
            <div className="d-flex mx-auto px-auto">
                <h4 className="text-secondary">
                {t("aboutUs.description")}
                </h4>
            </div>
            </div>
        </div>
        </div>
        <CallToAction
        header={t("aboutCta.header")}
        link="/contact"
        button={t("aboutCta.button")}
        ></CallToAction>
    </React.Fragment>
  )
}

export {About} 
