import React from 'react'

function TestimonialCard({description,name,job,img}) {
  return (
    <div className="testimonial-item rounded p-4 p-lg-5 mb-5 d-flex flex-column " style={{minHeight:"420px"}}>
        <div className='mb-auto  '>
            <p className="mb-4 " style={{fontStyle:"italic"}}>{description}</p>
        </div>
        <div className='mt-auto'>
            <h5 className=''>{name}</h5>
            <span className="text-primary">{job}</span>
        </div>         
    </div>
  )
}

export {TestimonialCard}
