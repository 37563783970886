import React from 'react'
import { ProjectsHome } from '../Components/ProjectsHome'
import { TestimonalHome } from '../Components/TestimonalHome'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BsArrowRight } from 'react-icons/bs'


function Home(props) {
  const {landing,multipage,consulting,mvp} = props;
  const [t] = useTranslation("global");
 
  return (
    <React.Fragment>
    <section className="mb-5 py-5 bg-primary text-white" > 
        <div className="container my-5 py-5">
          <div className="row justify-content-center">
            <div className="col-md-7 text-center">
              <h1 className="display-4 mb-3 text-white ">{t("home.title")}</h1>
              <p className="lead mb-5 fs-3">{t("home.subtitle")} </p>
              <a href="#app"  className="cta text-white">
                <span>{t("home.cta")}</span>
                <svg width="13px" height="10px" viewBox="0 0 13 10">
                  <path d="M1,5 L11,5"></path>
                  <polyline points="8 1 12 5 8 9"></polyline>
                </svg>
              </a>
            </div>
          </div>
        </div>
    </section>

    <div className='container-fluid py-4 mt-5 mb-3 ' id="app" >
        <div className='container' >
          <div className='row align-items-center'>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
              <video src="/img/app.mp4" autoPlay="true" loop="true" className="img-fluid" alt="imagen" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4 ">
              <h1 className='display-1'>{t("home.apps")}</h1>
              <h4 className='mt-3'>
                {t("home.appsContent")}
              </h4>
            </div>
          </div>
        </div>
      </div>
  
      <div className='container-fluid py-4 mt-5 mb-3'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className=" col-xl-6 col-lg-6 col-md-6 col-sm-12 order-sm-2 ">
              <video src="/img/web.mp4" autoPlay="true" loop="true" className="img-fluid" alt="imagen" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4">
            <h1 className='display-1'>{t("home.web")}</h1>
              <h4 className='mt-3'>
                {t("home.webContent")}
              </h4>
              
            </div>
  
          </div>
        </div>
      </div>

    <div className='container-fluid mb-lg-5 mb-md-3 bg-primary '>
        <div className='container '>
          <div className="row justify-content-center align-items-center pb-4">
            
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 pt-5">
              <h1 className='display-1 text-white'>{t("home.services")}</h1>
              <h4 className='text-white'>{t("home.servicesContent")}</h4>
            </div>
            
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 pt-md-5 px-4  mb-4">
              <div className="devided-class-w mt-4 me-lg-5" >
  
                <ul className="list-unstyled ">
                  <li className="flex fs-1 pb-0 " >
                    <NavLink to="/discovery" className="text-white service_link">
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className='service_title text-white'>{t("discoveryProductStrategy")}</span>
                    </NavLink>
                  </li>
                  <div className="hr-thick mt-4"></div>
                  <li className="list-style flex fs-1 pb-0 text-white">
                    <NavLink to="/design" className="text-white service_link">
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className='service_title'>{t("experienceDesign")}</span>
                    </NavLink>
                  </li>
                  <div className="hr-thick mt-4"></div>
                  <li className="list-style flex fs-1 pb-0 ">
                    <NavLink to="/software-development" className="text-white service_link">
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className='service_title'>{t("softwareDevelopment")}</span>
                    </NavLink>
                  </li>
                  <div className="hr-thick mt-4"></div>
                  <div className="hr-thick mt-4"></div>
                  <li className="list-style flex fs-1 pb-0 ">
                    <NavLink to="/outsourcing-and-staff-augmentation" className="text-white service_link">
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className='service_title'>{t("outsourcingStaffAugmentation")}</span>
                    </NavLink>
                  </li>
                  <div className="hr-thick mt-4"></div>
                </ul>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    <ProjectsHome/>
    <TestimonalHome/>
    <div className="container-fluid bg-primary p-4">
      <div className="d-flex flex-column p-5 align-items-center">
        <h1 className="text-light">{t('helpSection.title')}</h1>
        <div className='d-flex flex-row'>
          <a className='d-flex' href="/contact">
            <button className="btn offset mt-3 btn-lg">
              <span className='mt-2'>{t('helpSection.contactButton')}</span>
            </button>
          </a>
          <a className="d-flex" href="/projects">
            <button className="btn offset mt-3 btn-lg">
              <span className='mt-2'>{t('helpSection.projectsButton')}</span>
            </button>
          </a>
        </div>
      </div>
    </div>

</React.Fragment>
  )
}

export {Home}

/*
    {landing && <LandingOffer />}
    {multipage && <MultipageOffer />}
    {consulting && <ConsultingOffer />}
    {mvp && <MvpModal/>}
*/