import React from 'react'
import { ProjectCard } from './ProjectCard'
import OwlCarousel from 'react-owl-carousel';
import { useTranslation } from 'react-i18next'

  
function ProjectsHome() {
    const [t] = useTranslation("global");
    const projects = [
        {
          name: t('projectsH.saphirus.name'),
          description: t('projectsH.saphirus.description'),
          link: '',
          img: 'saphirus',
        },
        {
          name: t('projectsH.onTime.name'),
          description: t('projectsH.onTime.description'),
          link: 'https://ontimefs.com',
          img: 'onTime',
        },
        {
          name: t('projectsH.anfix.name'),
          description: t('projectsH.anfix.description'),
          link: 'https://www.anfix.com/',
          img: 'stock',
        },
        {
          name: t('projectsH.socioplus.name'),
          description: t('projectsH.socioplus.description'),
          link: 'https://socioplus.com.ar/',
          img: 'socioplus',
        },
      ];

  return (
<div class="container-xxl pt-5">
    <div class="container">
        <div class="text-center text-md-start mb-2 pb-5 pb-md-0 wow fadeInUp" data-wow-delay="0.1s"
            style={{maxWidth: "500px"}}>
            <p class="fs-5 fw-medium text-primary">{t("projectsH.title")}</p>
            <h1 class="display-5 mb-5">{t("projectsH.subtitle")}</h1>
        </div>

        <OwlCarousel className="owl-carousel project-carousel wow fadeInUp" 
                center
                autoplay 
                
                responsive={{
                    0:{
                        items:1
                    },
                    600:{
                        items:2
                    },
                    1000:{
                        items:3
                    }}} 
                    loop 
                    margin={10} 
                    nav>
                        {projects.map(projectCard => {
                                    return (
                                        <ProjectCard
                                            name={projectCard.name}
                                            description={projectCard.description}
                                            link={"/projects#"+projectCard.name.toLowerCase().replace(/[\s/'&]+/g, "-")}
                                            img={projectCard.img}
                                        />

                                    )
                                })
                        }
        </OwlCarousel> 
    </div>
</div>
  )
}

export {ProjectsHome}
