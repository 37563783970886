import React from 'react'
import { ContactForm } from '../Components/ContactForm'
import { useTranslation } from 'react-i18next'


function Contact() {
const [t] = useTranslation("global");
  return (
    <React.Fragment>
        <div className="container-fluid py-5 mb-5 wow fadeIn bg-primary" data-wow-delay="0.1s">
    <div className="container text-center py-5">
      <h1 className="display-2 text-white mb-4 animated slideInDown">{t("contact.title")}</h1>
    </div>
  </div>
  <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5">
        <ContactForm></ContactForm>
  
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
          <h3 className="mb-4">{t("contact.contactDetails")}</h3>
          <div className="d-flex border-bottom pb-3 mb-3">
            <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
              <i className="fa fa-phone-alt text-white"></i>
            </div>
            <div className="ms-3">
              <h6>{t("contact.callUs")}</h6>
              <span>+1 (202) 883-3388</span>
            </div>
          </div>
          <div className="d-flex border-bottom pb-3 mb-3">
            <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
              <a href="mailto:info@rockingproduct.com">
                <i className="fa fa-envelope text-white"></i>
              </a>
            </div>
            <div className="ms-3">
              <a href="mailto:info@rockingproduct.com">
                <h6>{t("contact.mailUs")}</h6>
                <span className="text-secondary">info@rockingproduct.com</span>
              </a>
            </div>
          </div>
          <div className="d-flex border-bottom pb-3 mb-3">
            <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
              <a href="#">
                <i className="fab fa-whatsapp text-white"></i>
              </a>
            </div>
            <div className="ms-3">
              <a href="#">
                <h6>{t("contact.whatsapp")}</h6>
                <span className="text-secondary">{t("contact.personalizedAttention")}</span>
              </a>
            </div>
          </div>
          <div className="d-flex border-bottom pb-3 mb-3">
            <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
              <a target="_blank" href="https://www.linkedin.com/company/rockingproduct/">
                <i className="fab fa-linkedin-in text-white"></i>
              </a>
            </div>
            <div className="flex-shrink-0 btn-square bg-primary rounded-circle mx-2">
              <a target="_blank" href="https://www.instagram.com/rockingproduct/">
                <i className="fab fa-instagram text-white"></i>
              </a>
            </div>
            <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
              <a target="_blank" href="https://www.facebook.com/rockingproduct.official">
                <i className="fab fa-facebook-f text-white"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></React.Fragment>
  )
}

export {Contact}
