import React from 'react'

function ProjectCard({name,description,link,img}) {
    return (
      <div className="project-item mb-5" style={{minHeight:"450px"}}>
                  <div className="position-relative">
                  <picture>
                    <source srcSet={`/img/imagenes/sizes/320/${img}.avif`} media="(max-width: 320px)" type="image/avif" />
                    <source srcSet={`/img/imagenes/sizes/640/${img}.avif`} media="(max-width: 640px)" type="image/avif" />
                    <source srcSet={`/img/imagenes/sizes/960/${img}.avif`} media="(max-width: 960px)" type="image/avif" />
                    <source srcSet={`/img/imagenes/sizes/1280/${img}.avif`} media="(max-width: 1280px)" type="image/avif" />
                    <source srcSet={`/img/imagenes/sizes/320/${img}.webp`} media="(max-width: 320px)" type="image/webp" />
                    <source srcSet={`/img/imagenes/sizes/640/${img}.webp`} media="(max-width: 640px)" type="image/webp" />
                    <source srcSet={`/img/imagenes/sizes/960/${img}.webp`} media="(max-width: 960px)" type="image/webp" />
                    <source srcSet={`/img/imagenes/sizes/1280/${img}.webp`} media="(max-width: 1280px)" type="image/webp" />
                    <source srcSet={`/img/imagenes/sizes/320/${img}.png`} media="(max-width: 320px)" type="image/png" />
                    <source srcSet={`/img/imagenes/sizes/640/${img}.png`} media="(max-width: 640px)" type="image/png" />
                    <source srcSet={`/img/imagenes/sizes/960/${img}.png`} media="(max-width: 960px)" type="image/png" />
                    <source srcSet={`/img/imagenes/sizes/1280/${img}.png`} media="(max-width: 1280px)" type="image/png" />
                    <img className="img-fluid" src={`/img/imagenes/sizes/1280/${img}.webp`} alt="" />
                  </picture>
                      <div className="project-overlay">
                          
                          <a className="btn btn-lg-square btn-light rounded-circle m-1" href={link} target="_blank"><i
                                  className="fa fa-link"></i></a>
                      </div>
                  </div>
                  <div className="p-4">
                      <a className="d-block h5" href="">{name}</a>
                      <span>{description}</span>
                  </div>
                  
      </div>
    )
  }

export {ProjectCard}
/*

*/