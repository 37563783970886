import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function OfferCard({img,title,desc,btn,handleClick,buy}) {

  console.log({img})
  return (
    <Card className="d-flex m-1 p-2">
      <picture>
          <source srcSet={`/img/imagenes/sizes/320/${img}.avif`} media="(max-width: 320px)" type="image/avif" />
          <source srcSet={`/img/imagenes/sizes/640/${img}.avif`} media="(max-width: 640px)" type="image/avif" />
          <source srcSet={`/img/imagenes/sizes/960/${img}.avif`} media="(max-width: 960px)" type="image/avif" />
          <source srcSet={`/img/imagenes/sizes/1280/${img}.avif`} media="(max-width: 1280px)" type="image/avif" />
          <source srcSet={`/img/imagenes/sizes/320/${img}.webp`} media="(max-width: 320px)" type="image/webp" />
          <source srcSet={`/img/imagenes/sizes/640/${img}.webp`} media="(max-width: 640px)" type="image/webp" />
          <source srcSet={`/img/imagenes/sizes/960/${img}.webp`} media="(max-width: 960px)" type="image/webp" />
          <source srcSet={`/img/imagenes/sizes/1280/${img}.webp`} media="(max-width: 1280px)" type="image/webp" />
          <source srcSet={`/img/imagenes/sizes/320/${img}.png`} media="(max-width: 320px)" type="image/png" />
          <source srcSet={`/img/imagenes/sizes/640/${img}.png`} media="(max-width: 640px)" type="image/png" />
          <source srcSet={`/img/imagenes/sizes/960/${img}.png`} media="(max-width: 960px)" type="image/png" />
          <source srcSet={`/img/imagenes/sizes/1280/${img}.png`} media="(max-width: 1280px)" type="image/png" />
          <img className="img-fluid" src={`/img/imagenes/sizes/1280/${img}.avif`} alt="offer-card" />
      </picture>
      <Card.Body>
        <Card.Title>{title}</Card.Title>
        <Card.Text>
          {desc}
        </Card.Text>
        <Button variant="dark" onClick={handleClick}>{btn}</Button>
        {buy? <Button variant="primary" href={buy} target='_blank'>Buy Now!</Button>: 
        null
        }
      </Card.Body>
    </Card>
  );
}

export {OfferCard};