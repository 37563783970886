import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function Navbar() {
    const { t, i18n } = useTranslation("global");
    const location = useLocation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        console.log(lng);
      };

  return (
    <div className="container-fluid text-white sticky-top black" >
            <div className="row">
                <nav className="navbar navbar-expand-xl black navbar-dark p-lg-0 ">
                    <a href="/" className="">
                    <img href="/" className="ms-md-5 ps-md-5 my-2 img-fluid" src="./assets/logo-web_horizontal-white.png" 
    style={{ maxHeight: "70px", maxWidth: "95%", height: "auto" }}
/>
                    </a>
                    <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end col-3 montserrat" id="navbarCollapse">
                        <div className="navbar-nav me-3 pe-3 pt-1">

                            <NavLink to="/" className="nav-item nav-link">{t("navbar.home")}</NavLink>
                            <NavLink to="/services" className="nav-item nav-link text-nowrap">{t("navbar.services")}</NavLink>
                            <NavLink to="/projects" className="nav-item nav-link">{t("navbar.projects")}</NavLink>
                            <NavLink to="/about" className="nav-item nav-link">{t("navbar.about")}</NavLink>
                            <NavLink to="/contact" className="nav-item nav-link">{t("navbar.contact")}</NavLink>
                            <div className="mt-2">
                            <a className="pais mb-2"  onClick={() => changeLanguage('es')}>
                                <img src="./img/argentina.png" style={{ maxHeight: "20px" }} alt="Argentina" />
                            </a>
                            <a className="pais"  onClick={() => changeLanguage('en')}>
                                <img src="./img/american.jpg" style={{ maxHeight: "20px" }} alt="American" />
                            </a>
                            </div>
                        </div>

                    </div>
                </nav>
            </div>                
        </div>
  )
}

export {Navbar}
/*
<NavLink to="/special-offers" className="nav-item nav-link" style={{marginRight:"20px"}} title="Offers"><img className="sales" src="/img/imagenes/sale.webp" /></NavLink>
                            
<a href="/" class=" nav-item nav-link active">Home</a>
<a href="/services" class=" nav-item nav-link" >Services</a>
<a href="/projects" class="nav-item nav-link">Projects</a>
<a href="/about" class=" nav-item nav-link">About</a>
<a href="/contact" class=" nav-item nav-link">Contact</a>
<NavLink to="/special-offers" className="nav-item nav-link" style={{marginRight:"20px"}} title="Offers"><img className="sales" src="/img/imagenes/sale.webp" /></NavLink>
                           
*/