import React from 'react';
import './App.css';
import { Navbar } from './Components/navbar';
import { Route, Routes } from 'react-router-dom';
import { Home } from './Pages/Home';
import { ServicesOld } from './Pages/ServicesOld';
import { Projects } from './Pages/Projects';
import { About } from './Pages/About';
import { Contact } from './Pages/Contact';
import { Offers } from './Pages/Offers';
import { useEffect } from 'react';
import {Services} from './Pages/Services';
import { Discovery } from './Pages/Discovery';
import { Design } from './Pages/Design';
import { SoftwareDevelopment } from './Pages/SoftwareDevelopment';
import { Footer } from './Components/footer';
import { Outsourcing } from './Pages/Outsourcing';
function App() {

  useEffect(() => {
        
    if (window.location.hash) {
      const el = document.getElementById(window.location.hash.slice(1));
      if (el) {
        setTimeout(()=>{
        el.scrollIntoView()}
        ,10);
      }
    }
  }, []);

  return (
    <React.Fragment>
      
      <Navbar/>
      <Routes>
      <Route path="/" element={<Home modal=""/>} />
      <Route path="/land" element={<Home landing={true} />} />
      <Route path="/web" element={<Home multipage={true} />} />
      <Route path="/cons" element={<Home consulting={true} />} />
      <Route path="/mvp" element={<Home mvp={true} />} />
        <Route path="/projects/:anchor?" element={<Projects />} />
        <Route path="/servicesOld/:anchor?" element={<ServicesOld />} />
        <Route path="/services" element={<Services />} />
          <Route path="/services/discovery" element={<Discovery/>} />
          <Route path="/discovery" element={<Discovery/>} />
          <Route path="/design" element={<Design />} />
          <Route path="/software-development" element={<SoftwareDevelopment />} />
        <Route path="/about" element={<About />} />
        <Route path="/outsourcing-and-staff-augmentation" element={<Outsourcing />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/special-offers/:anchor?" element={<Offers />} />
      </Routes>
      <Footer/>
    </React.Fragment>
  );
}

export default App;