import React from 'react'
import { NavLink } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'

function Services() {
  const [t,i18n] = useTranslation("global");
  
  return (
    <>
      <div className="container-fluid py-5 mb-5 wow fadeIn" style={{ background: "black" }}>
        <div className="container text-left py-5">
          <h1 className="display-2 text-white mb-4">{t("supportingTitle")}</h1>
          <h4 className="text-white mb-4">{t("supportingSubtitle")}</h4>
        </div>
      </div>
  
      <div className='container-fluid mb-lg-5 mb-md-3'>
        <div className='container'>
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  mb-4">
              <div className="text-style-title  text-dark pb-lg-2 "><strong>{t("howCanWeHelp")}</strong></div>
              <div className="devided-class mt-4 ">
  
                <ul className="list-unstyled  ">
                  <li className="flex fs-1 pb-0">
                    <NavLink to="/discovery" className="text-dark service_link">
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className='service_title'>{t("discoveryProductStrategy")}</span>
                    </NavLink>
                  </li>
                  <div className="hr-thick mt-4"></div>
                  <li className="list-style flex fs-1 pb-0 ">
                    <NavLink to="/design" className="text-dark service_link">
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className='service_title'>{t("experienceDesign")}</span>
                    </NavLink>
                  </li>
                  <div className="hr-thick mt-4"></div>
                  <li className="list-style flex fs-1 pb-0 ">
                    <NavLink to="/software-development" className="text-dark service_link">
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className='service_title'>{t("softwareDevelopment")}</span>
                    </NavLink>
                  </li>
                  <div className="hr-thick mt-4"></div>
                  <div className="hr-thick mt-4"></div>
                  <li className="list-style flex fs-1 pb-0 ">
                    <NavLink to="/outsourcing-and-staff-augmentation" className="text-dark service_link">
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className="arrow-icon">
                        <BsArrowRight className="ms-5 mb-4" />
                      </span>
                      <span className='service_title'>{t("outsourcingStaffAugmentation")}</span>
                    </NavLink>
                  </li>
                  <div className="hr-thick mt-4"></div>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
              <div className="img-container-services ">
                <img src="/img/imagenes/teamServices.webp" className="img-fluid" alt="Imagen" />
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div className="container-fluid mt-3 mb-4 py-5 " style={{ backgroundColor: "#00FFFF" }}>
        <div className='container'>
          <div className='row'>
            <h1 className="text-dark display-6 pb-5">{t("makeIdeaReality")}</h1>
            <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12 ">
              <h3 className="text-dark my-4"><strong>{t("whatWeDo")}</strong></h3>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12   ">
              <h5 className="">{t("accomplishGoals")}</h5>
            </div>
          </div>
        </div>
      </div>
  
      <div className='container-fluid py-4 mt-5 mb-3 '>
        <div className='container'>
  
          <div className='row align-items-center'>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
              <img src="/img/imagenes/discovery.webp" className="img-fluid" alt="imagen" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4 ">
              <h2>{t("discoveryProductStrategy")}</h2>
              <h5 className='mt-3'>
                {t("discoveryProductStrategyDescription")}
              </h5>
              <div className="des_tag mt-3" style={{ fontSize: "14px" }}>
                <div className="col-md-5 "><p className="service_tag">{t("businessCaseKPIs")}</p></div>
                <div className="col-md-5 "><p className="service_tag">{t("northStarProductVision")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("dataStrategy")}</p></div>
                <div className="col-md-5 "><p className="service_tag">{t("personasArchetypes")}</p></div>
                <div className="col-md-5 "><p className="service_tag">{t("marketTrendAnalysis")}</p></div>
                <div className="col-md-5 "><p className="service_tag">{t("portfolioOptimization")}</p></div>                                        </div>
              <NavLink to="/discovery" className="">
                <button className="buttonLearnMore text-dark">
                  {t("learnMore")}
                </button>
              </NavLink>
            </div>
  
          </div>
        </div>
      </div>
  
      <div className='container-fluid py-4 my-3'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className="image-container col-xl-6 col-lg-6 col-md-6 col-sm-12 order-sm-2 ">
              <img src="/img/imagenes/design.webp" className="img-fluid" alt="imagen" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
              <h2>{t("experienceDesign")}</h2>
              <h5 className='mt-3'>
                {t("experienceDesignDescription")}
              </h5>
              <div className="des_tag mt-3" style={{ fontSize: "14px" }}>
                <div className="col-md-5"><p className="service_tag">{t("brandStrategy")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("customerJourneyMapping")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("userInsightValidation")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("uxUiDesign")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("copywriting")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("buyerJourney")}</p></div>
              </div>
              <NavLink to="/design" className="">
                <button className="buttonLearnMore text-dark img-fluid">
                  {t("learnMore")}
                </button>
              </NavLink>
            </div>
  
          </div>
        </div>
      </div>
  
      <div className='container-fluid py-4 my-3'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
              <img src="/img/imagenes/development.webp" className="img-fluid" alt="imagen" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ">
              <h2>{t("softwareDevelopment")}</h2>
              <h5 className='mt-3'>
                {t("softwareDevelopmentDescription")}
              </h5>
              <div className="des_tag mt-3" style={{ fontSize: "14px" }}>
                <div className="col-md-5"><p className="service_tag">{t("fullStackDevelopment")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("apiDevelopment")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("systemsIntegrations")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("virtualReality")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("supportMaintenance")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("augmentedReality")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("blockchain")}</p></div>
                <div className="col-md-5"><p className="service_tag">{t("aiMachineLearning")}</p></div>
              </div>
              <NavLink to="/software-development" className="">
                <button className="buttonLearnMore text-dark img-fluid">
                  {t("learnMore")}
                </button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className='container-fluid py-4 my-3'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className="image-container col-xl-6 col-lg-6 col-md-6 col-sm-12 order-sm-2 ">
              <img src="/img/imagenes/staff-1.jpg" className="img-fluid" alt="imagen" />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
              <h2>{t("staffTitle")}</h2>
              <h5 className='mt-3'>
                {t("staffDescription")}
              </h5>
              <NavLink to="/outsourcing-and-staff-augmentation" className="">
                <button className="buttonLearnMore text-dark img-fluid">
                  {t("learnMore")}
                </button>
              </NavLink>
            </div>
  
          </div>
        </div>
      </div>
    </>
  )
}

export {Services}

/*

   <section className="section-service-1 bg-white  ">
     <div className="service-1 row justify-content-center">
       <div className="container-service col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4">

         <h1 className="service1-text1">
           Software
         </h1>


         <p className="service-parrafo text-dark">
           Agile design and engineering of custom applications and platforms,
           with an emphasis on quality, relevancy, and time to market.
         </p>
         <hr className="hr-line" />

         <div className="option-design row justify-content-between ">
           <div className="lista-h1 col-md-6 col-sm-12">
             <ul className="ul1">
               <li className="li1 ">Full Stack development</li>
               <li className="li1">API development</li>
               <li className="li1">MVP</li>
             </ul>
           </div>

           <div className="lista2-h1 col-md-6 col-sm-12">
             <ul className="ul2">
               <li className="li2">Agile Management</li>
               <li className="li2">Systems integration</li>
               <li className="li2">Support & Maintenance</li>
             </ul>
           </div>


         </div>

         <NavLink to="/software-development/software/learnmore" className="">
           <button className="buttonLearnMore text-dark">
             Learn More
           </button>
         </NavLink>
       </div>
       <div className="image-container col-xl-4 col-lg-6 col-md-6 col-sm-12 mb-4">
         <img src="/img/imagenes/development.webp" className="img-fluid" alt="imagen" />
       </div>

     </div>
   </section>


 </div>

*/ 