import React from 'react'
import { OfferCard } from '../Components/OfferCard'
import { ContactFormOffer } from '../Components/ContactFormOffer'

function Offers() {
 

  const handleClick = (subject,code) => {
    const formDiscountContainer = document.getElementById("form-discount");
    formDiscountContainer.scrollIntoView({ behavior: "smooth" });

    const _code = document.getElementById("code");
    const _subject = document.getElementById("subject")
    setTimeout(()=>{
        _subject.value = subject
        _code.value = code
    },500)
  }

  

  return (
    <React.Fragment>
        <div className="container-fluid py-5 mb-5 wow fadeIn bg-primary" data-wow-delay="0.1s">
            <div className="container text-center py-5">
                <h1 className="display-2 text-white mb-4 animated slideInDown" id="offers">Special Offers %</h1>
            </div>
        </div>
        <div className="container-xxl py-5 offers" >
            <div className="container">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" >
                </div>
                    <div className='row'>
                    <div className='col-sm-3' id="landing">
                        <OfferCard
                            btn="More Info"
                            img="landing-light"
                            desc="Want to improve your website's conversion rate? An effective landing page is the key. Create a custom page that conveys your message clearly and persuasively, and converts visitors into leads. With our help, you can increase your conversion rate and grow your business. Contact us today and let's create a landing page that delivers results!"
                            handleClick={()=>handleClick("50% Landing Page", "LAND383")}
                            buy="https://buy.stripe.com/28o29i1RPdRx5gIeUV"
                        />
                    </div>
                    <div className='col-sm-3'>
                        <OfferCard
                            btn="More Info"
                            img="multipage2"
                            desc="Ready to take your online presence to the next level? Showcase your brand, products, and services with a comprehensive and engaging online experience that informs, educates, and persuades your visitors to take action. We can help you create a multipage website that stands out from your competitors and achieves your business objectives."
                            handleClick={()=>handleClick("50% Website Multipage", "MULT665")}
                            buy="https://buy.stripe.com/8wMeW4aoldRx24wfZ2"
                        />
                    </div>
                    <div className='col-sm-3'>
                        <OfferCard
                            btn="More Info"
                            img="consultingOffer"
                            desc="Are you looking to take your business to new heights in the digital world? Our consulting service offers guidance on everything from business models and engineering to canvas models, tech trends, design, and scalability. We don't just create websites ands apps - we help you build successful digital products that meet your business objectives."
                            handleClick={()=>handleClick("50% Consulting", "CONS377")}
                            buy="https://buy.stripe.com/9AQ15edAxcNtbF66or"
                        />
                    </div>
                    <div className='col-sm-3'>
                        <OfferCard
                            btn="More Info"
                            img="mvpOffer"
                            desc=" We specialize in developing high-performing platforms and apps, from MVPs to complex applications. Our experienced developers can bring any idea to life, no matter how simple or complex your requirements. Whether you need a web app, mobile app, or custom software solution, we're here to be your technological partners. "
                            handleClick={()=>handleClick("30% Custom Development", "MVP822")}
                        />
                    </div>
                </div> 
            </div>
        </div>
        <div className="container-fluid py-5 my-5 bg-light " id="form-discount" >
           <div className="row g-5 justify-content-center form-discount"  >
                <ContactFormOffer/>
            </div>
        </div>
    </React.Fragment>
  )
}

export {Offers} 
