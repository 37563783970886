import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next'

const Result = ({ setShowForm }) => {
  const handleSendNewMessage = () => {
    setShowForm(true);
  };
  const [t] = useTranslation("global");
  return (
    <div>
      <h3>{t("messageSent")}</h3>
      <button className="btn btn-primary rounded-pill py-3 px-5" onClick={handleSendNewMessage}>{t("sendMessage")}</button>
    </div>
  );
};

function ContactFormServices({code}) {
  const form = useRef();
  const [showForm, setShowForm] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();
    
    const message = e.target.message.value.trim(); 
    if (!message) {
        alert('Please enter a message.'); 
        return;
    }

    emailjs.sendForm('service_2a3hcay', 'template_05cpehc', form.current, 'fUnUHqxfTevW6lLoQ')
      .then((result) => {
        console.log(result.text);
        setShowForm(false);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  const [t] = useTranslation("global");

  return (
    <div className="col-lg-6 wow fadeInUp py-3 px-5" style={{backgroundColor:"#000000",borderRadius:"10px"}}  data-wow-delay="0.1s">
  {showForm ? (
    <div className=''>
      <h3 className="my-4 text-white">{t("contactForm.title")}</h3>
      <form ref={form} onSubmit={sendEmail}>
        <div className="row g-3">
          <div className="col-md-6">
            <div className="form-floating">
              <input type="text" className="form-control" id="name" placeholder={t("contactForm.fullNamePlaceholder")} name="user_name" />
              <label htmlFor="name">{t("contactForm.fullNameLabel")}</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating">
              <input type="text" className="form-control " id="company" placeholder={t("contactForm.companyNamePlaceholder")} name="company" />
              <label htmlFor="company">{t("contactForm.companyNameLabel")}</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating">
              <input type="email" className="form-control" id="email" placeholder={t("contactForm.emailPlaceholder")} name="email" />
              <label htmlFor="email">{t("contactForm.emailLabel")}</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-floating">
              <input type="phone" className="form-control " id="phone" placeholder={t("contactForm.phonePlaceholder")} name="phone" />
              <label htmlFor="phone">{t("contactForm.phoneLabel")}</label>
            </div>
          </div>
          <div className="col-12">
            <div className="form-floating">
              <textarea className="form-control" placeholder={t("contactForm.messagePlaceholder")} id="message" style={{ height: '200px' }} name="message" />
              <label htmlFor="message">{t("contactForm.messageLabel")}</label>
            </div>
          </div>
          <div className="col-12">
            <button className="btn rounded-pill py-3 px-5 mb-4" style={{backgroundColor:"#00FFFF",color:"#000000"}} type="submit" value="Send"><strong>{t("contactForm.sendMessageButton")}</strong></button>
          </div>
        </div>
      </form>
    </div>
      ) : (
        <Result setShowForm={setShowForm} />
      )}
    </div>
  );
}

export { ContactFormServices };