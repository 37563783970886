import React from 'react'
import { useTranslation } from 'react-i18next';

function Footer() {
    const { t } = useTranslation("global");
  return (
    <div className="container-fluid bg-light text-dark footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-4 col-md-6 px-5">
            <img
              className="mb-4"
              src="/img/imagenes/logo-web_horizontal-black.png"
              style={{ maxHeight: "70px", maxWidth: "300px" }}
              alt="Logo"
            />
            <p className="mb-2 ps-4">
              <i className="fa fa-phone-alt me-3"></i>
              {t("footer.phone")}
            </p>
            <p className="mb-2 ps-4">
              <i className="fa fa-envelope me-3"></i>
              {t("footer.email")}
            </p>
            <p className="mb-2 ps-4">
              <i className="fa fa-copyright -alt me-3"></i>
              {t('footer.copyRight')}
            </p>
          </div>
          <div className="col-lg-4 col-md-6 px-5">
            <h4 className="mb-4">{t('footer.quickLinks')}</h4>
            <a className="btn btn-link" href="/contact">
              {t('footer.contactUs')}
            </a>
            <a className="btn btn-link" href="/">
              {t('footer.home')}
            </a>
            <a className="btn btn-link" href="/services">
              {t('footer.services')}
            </a>
            <a className="btn btn-link" href="/projects">
              {t('footer.projects')}
            </a>
            <a className="btn btn-link" href="/about">
              {t('footer.aboutUs')}
            </a>
          </div>
          <div className="col-lg-4 col-md-6 px-5">
            <h4 className="mb-1">{t('footer.contact')}</h4>
            <div className="d-flex pt-3">
              <a
                className="btn btn-square btn-light rounded-circle me-2"
                target="_blank"
                href="https://www.linkedin.com/company/rockingproduct/"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a
                className="btn btn-square btn-light rounded-circle me-2"
                target="_blank"
                href="https://www.instagram.com/rockingproduct/"
              >
                <i className="fab fa-instagram"></i>
              </a>
              <a
                className="btn btn-square btn-light rounded-circle me-2"
                target="_blank"
                href="https://www.facebook.com/rockingproduct.official"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
              <a
                className="btn btn-square btn-light rounded-circle me-2"
                target="_blank"
                href="https://www.facebook.com/rockingproduct.official"
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            </div>
            <div>
              <div className="mt-4 mb-md-0">
                <img className="payment-logo" src="/img/visa.svg" alt="Visa" />
                <img className="payment-logo" src="/img/mastercard.svg" alt="Mastercard" />
                <img className="payment-logo" src="/img/paypal.svg" alt="PayPal" />
                <img className="payment-logo" src="/img/bank-transfer.svg" alt="Bank Transfer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {Footer}
