import React from 'react'
import { ContactFormServices } from '../Components/ContactFormServices'
import { useTranslation } from 'react-i18next'

function Outsourcing() {
    const [t,i18n] = useTranslation("global");
    return (
      <>
        <div className="container-fluid py-5 mb-5 wow fadeIn" style={{ background: "black" }}>
          <div className="container text-left py-5">
            <p className="mb-4 d-inline-block"><a className="text-secondary d-inline-block" href="/services">{t("navbar.services")}&nbsp;&nbsp;&nbsp;&nbsp;&gt;</a>
              <a className='d-inline-block text-white'>&nbsp;&nbsp;&nbsp;&nbsp; {t("outsourcing.desc")}</a>
            </p>
            <h1 className="display-2 text-white mb-4 ">{t("outsourcing.title")}</h1>
            <h4 className=" text-white mb-4 ">
            </h4>
          </div>
        </div>
  
        <div className='container-fluid py-4 mt-5 mb-3 '>
          <div className='container'>
            <div className='row align-items-center'>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
                <img src="/img/imagenes/staff-2.webp" className="img-fluid" alt="imagen" />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4 ">
                <h2><strong>{t("outsourcing.section1Subtitle")}</strong>
                </h2>
                <h5 className='mt-3'>
                  {t("outsourcing.section1Content")}
                </h5>
                
              </div>
            </div>
          </div>
        </div>
  
        <div className="container-fluid mt-3 mb-4 py-5  " style={{ backgroundColor: "#00FFFF" }}>
          <div className='container'>
            <div className='row'>
              <h1 className=" text-dark display-6 pb-5">
                {t("outsourcing.section2Title")}
              </h1>
              <h5>
                {t("outsourcing.section2Content")}
              </h5>
            </div>
          </div>
        </div>
  
        <div className='container-fluid py-4 mt-5 mb-3 '>
          <div className='container'>
  
            <div className='row align-items-center'>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
                <img src="/img/imagenes/staff-5.webp" className="img-fluid" alt="imagen" />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4 ">
                <h2>{t("outsourcing.section3Title")}
                </h2>
                <h5 className='mt-3'>
                  {t("outsourcing.section3Content")}
                </h5>
  
              </div>
  
            </div>
          </div>
        </div>
  
        <div className='container-fluid py-4 my-3'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className="image-container col-xl-6 col-lg-6 col-md-6 col-sm-12 order-sm-2 ">
                <img src="/img/imagenes/staff-2.webp" className="img-fluid" alt="imagen" />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
                <h2>{t("outsourcing.section4Title")}</h2>
                <h5 className='mt-3'>
                  {t("outsourcing.section4Content")}
                </h5>
  
              </div>
  
            </div>
          </div>
        </div>
  
        <div className='container-fluid py-4 my-3' id="software-development">
          <div className='container'>
  
            <div className='row align-items-center'>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
                <img src="/img/imagenes/staff-3.webp" className="img-fluid" alt="imagen" />
              </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4">
              <h2>{t("outsourcing.section5Title")}
              </h2>
              <h5 className='mt-3'>
                {t("outsourcing.section5Content")}
              </h5>
              
            </div>
        
          </div>
        </div>
      </div>
  
      <div className='container-fluid py-4 my-3'>
    <div className='container'>
      <div className='row align-items-center'>
      <div className="image-container col-xl-6 col-lg-6 col-md-6 col-sm-12 order-sm-2 ">
          <img src="/img/imagenes/staff-6.webp" className="img-fluid" alt="imagen" />
        </div>
        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
          <h2>{t("outsourcing.section6Title")}</h2>
          <h5 className='mt-3'>
            {t("outsourcing.section6Content")}
          </h5>
        </div>
        
      </div>
    </div>
  </div>
  <div className="container-fluid py-3 my-3 " id="form-discount" >
             <div className="row g-5 justify-content-center form-discount"  >
                  <ContactFormServices/>
              </div>
          </div>
      </>
  
    )
}

export {Outsourcing}
