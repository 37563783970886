import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';

const Result = ({ setShowForm }) => {
  const handleSendNewMessage = () => {
    setShowForm(true);
  };

  return (
    <div>
      <h3>Your message has been successfully sent. We will contact you immediately!</h3>
      <button className="btn btn-primary rounded-pill py-3 px-5" onClick={handleSendNewMessage}>Send a new message</button>
    </div>
  );
};

function ContactFormOffer({code}) {
  const form = useRef();
  const [showForm, setShowForm] = useState(true);

  const sendEmail = (e) => {
    e.preventDefault();
    
    const message = e.target.message.value.trim(); 
    if (!message) {
        alert('Please enter a message.'); 
        return;
    }

    emailjs.sendForm('service_2a3hcay', 'template_05cpehc', form.current, 'fUnUHqxfTevW6lLoQ')
      .then((result) => {
        console.log(result.text);
        setShowForm(false);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  return (
    <div className="col-lg-6 wow fadeInUp"  data-wow-delay="0.1s">
      {showForm ? (
        <div className=''>
          <h3 className="my-4">We can't wait to see what we can achieve together!</h3>
          <form ref={form} onSubmit={sendEmail}>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="form-floating">
                  <input type="text" className="form-control" id="name" placeholder="Your Name" name="user_name" />
                  <label htmlFor="name">Your Name</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <input type="email" className="form-control " id="email" placeholder="Your Email" name="user_email" />
                  <label htmlFor="email">Your Email</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <input type="text" className="form-control" id="subject" placeholder="Subject" name="subject" />
                  <label htmlFor="subject">Subject</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <textarea className="form-control" placeholder="Leave a message here" id="message" style={{ height: '200px' }} name="message" />
                  <label htmlFor="message">Message</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <input className="form-control" placeholder="Promotional code" id="code" name="code" value={code}/>
                  <label htmlFor="code">Promotional Code</label>
                </div>
              </div>
              <div className="col-12">
                <button className="btn btn-primary rounded-pill py-3 px-5" type="submit" value="Send">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <Result setShowForm={setShowForm} />
      )}
    </div>
  );
}

export { ContactFormOffer };