import React from 'react'
import { ContactFormServices } from '../Components/ContactFormServices'
import { useTranslation } from 'react-i18next'

function Design() {
  const [t] = useTranslation("global");
  return (
    <>
    <div className="container-fluid py-5 mb-5 wow fadeIn" style={{background:"black"}} >
  <div className="container text-left py-5">
    <p className='mb-4 d-inline-block'><a className="text-secondary d-inline-block" href="/services">Services&nbsp;&nbsp;&nbsp;&nbsp;&gt;</a>
    <a className='d-inline-block text-white'>&nbsp;&nbsp;&nbsp;&nbsp;{t("design.title")}</a>
    </p>
    <h1 className="display-2 text-white mb-4 " >{t("design.heading")}</h1>
    <h4 className=" text-white mb-4 " >
    </h4>
  </div>
</div>

<div className='container-fluid py-4 mt-5 mb-3 '>
  <div className='container'>
    <div className='row align-items-center'>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
        <img src="/img/imagenes/design-1.webp" className="img-fluid" alt="imagen" />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4 ">
        <h2><strong>{t("design.salesmenQuote")}</strong></h2>
        <h5 className='mt-3'>
        {t("design.salesmenDescription")}
        </h5>
      </div>
    </div>
  </div>
</div>

<div className="container-fluid mt-3 mb-4 py-5  " style={{backgroundColor:"#00FFFF"}}>
  <div className='container'>
    <div className='row'>
      <h1 className=" text-dark display-6 pb-5">{t("design.uxFlowHeading")}</h1>
      <h5>
      {t("design.uxFlowDescription")}
      </h5>
    </div>
  </div>
</div>

<div className='container-fluid py-4 mt-5 mb-3 '>
  <div className='container'>
    <div className='row py-3'>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 pe-lg-5 mt-4">
        <h5 className='mt-3'><strong>{t("design.readTitle")}</strong></h5>
        <h5 className='mt-3'>{t("design.readDescription")}</h5>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12  ps-lg-5 mt-4 text-secondary">
        <h5 className='mt-3'><strong>{t("design.clientTitle")}</strong></h5>
        <h5 className='mt-3'>{t("design.clientDescription")}</h5>
      </div>
    </div>
    <div className='row py-3'>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 pe-lg-5 mt-4 ">
        <h5 className='mt-3'><strong>{t("design.contentTitle")}</strong></h5>
        <h5 className='mt-3'>{t("design.contentDescription")}</h5>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ps-lg-5 mt-4">
        <h5 className='mt-3'><strong>{t("design.simpleTitle")}</strong></h5>
        <h5 className='mt-3'>{t("design.simpleDescription")}</h5>
      </div>
    </div>
  </div>
</div>

<div className='container-fluid py-4 mt-5 mb-3 '>
  <div className='container'>
    <div className='row align-items-center'>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4 ">
        <img src="/img/imagenes/design-2.webp" className="img-fluid" alt="imagen" />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 px-4 mt-4 ">
        <h2>{t("design.problemSolutionTitle")}</h2>
        <h5 className='mt-3'>
        {t("design.problemSolutionDescription")}
        </h5>
      </div>
    </div>
  </div>
</div>

<div className='container-fluid py-4 my-3'>
  <div className='container'>
    <div className='row align-items-center'>
      <div className="image-container col-xl-6 col-lg-6 col-md-6 col-sm-12 order-sm-2 ">
        <img src="/img/imagenes/design-3.webp" className="img-fluid" alt="imagen" />
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 ">
        <h2>{t("design.dataInsights.title")}</h2>
        <h5 className='mt-3'>
          {t("design.dataInsights.description")}
        </h5>
      </div>
    </div>
  </div>
</div>
  
<div className="container-fluid mt-3 mb-4 py-5" style={{backgroundColor:"#00FFFF"}}>
  <div className='container'>
    <div className='row'>
      <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12 ">
        <h3 className="text-dark my-4"><strong>{t("design.result.title")}</strong></h3>
      </div>
      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <h3 className="text-dark my-4"><strong>{t("design.result.experiencesTitle")}</strong></h3>
        <h5>
          {t("design.result.experiencesDescription")}
        </h5>
      </div>
    </div>
  </div>
</div>
  
<div className="container-fluid py-3 my-3 " id="form-discount" >
         <div className="row g-5 justify-content-center form-discount"  >
              <ContactFormServices/>
          </div>
      </div>
  </>

  )
}

export {Design} 
