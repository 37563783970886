import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import { CallToAction } from '../Components/CallToAction';
import { useTranslation } from 'react-i18next'

  
function Projects() {

    
    const [t] = useTranslation("global");
    const projects = [
        {
          name: t("projects.saphirus.name"),
          description: t("projects.saphirus.description"),
          link: 'https://saphirus.com.ar/',
          img: ['saphirus', 'saphirus2', 'saphirus3']
        },
        {
          name: t("projects.onTime.name"),
          description: t("projects.onTime.description"),
          link: 'https://ontimefs.com/',
          img: ['onTime', 'onTime2', 'onTime3']
        },
        {
          name: t("projects.anfix.name"),
          description: t("projects.anfix.description"),
          link: 'https://www.anfix.com/',
          img: ['stock', 'stock2', 'stock3']
        },
        {
          name: t("projects.socioplus.name"),
          description: t("projects.socioplus.description"),
          link: 'https://socioplus.com.ar/',
          img: ['socioplus', 'socioplus2', 'socioplus3']
        }
      ];
  return (
    <React.Fragment>
        <div className="container-fluid py-5 mb-5 wow fadeIn" style={{backgroundColor:"#506af0"}} data-wow-delay="0.1s">
        <div className="container text-center py-5">
            <h2 className="display-2 text-white mb-4 animated slideInDown">{t("projects.header")}</h2>
            <h3 className=" text-light" style={{marginTop: "0.5rem"}}>{t("projects.subheader")}</h3>
        </div>
    </div>

    <div className='mt-5 pt-5'>
      {projects.map(projectCard => {
                                    return (
                                        <div className="project-item mb-5 container bg-light project-content" id={projectCard.name.toLowerCase().replace(/[\s/'&]+/g, "-")} style={{minHeight:"450px",}}
                                        >
                                                   
                                                    <div className="p-4">
                                                        <h3 className="d-block mb-4" href="">{projectCard.name}</h3>
                                                        <span>{projectCard.description}</span>
                                                    </div>
                                                    <OwlCarousel className="owl-projects wow fadeInUp"  
                                                    nav
                                                    autoHeightClass
                                                    autoplay 
                                                    responsive={{
                                                        0:{
                                                            items:1
                                                        }}} 
                                                        loop 
                                                        margin={10} 
                                                        >
                                                    {
                                                        projectCard.img.map(img=>{
                                                            return(
                                                                <div className="position-relative project-img" > 
                                                                    <picture className='img-fluid'  >
                                                                        <source srcSet={`
                                                                            /img/imagenes/sizes/320/${img}.avif 320w,
                                                                            /img/imagenes/sizes/640/${img}.avif 640w,
                                                                            /img/imagenes/sizes/960/${img}.avif 960w,
                                                                            /img/imagenes/sizes/1280/${img}.avif 1280w,
                                                                            /img/imagenes/sizes/320/${img}.webp 320w,
                                                                            /img/imagenes/sizes/640/${img}.webp 640w,
                                                                            /img/imagenes/sizes/960/${img}.webp 960w,
                                                                            /img/imagenes/sizes/1280/${img}.webp 1280w,
                                                                            /img/imagenes/sizes/320/${img}.png 320w,
                                                                            /img/imagenes/sizes/640/${img}.png 640w,
                                                                            /img/imagenes/sizes/960/${img}.png 960w,
                                                                            /img/imagenes/sizes/1280/${img}.png 1280w`}
                                                                            type="image/avif, image/webp, image/png" />
                                                                        <img className="img-fluid" src={`/img/imagenes/sizes/1280/${img}.png`} alt="" style={{ height: "100%" ,objectFit: "contain"}} />
                                                                    </picture>
                                                                </div>
                                                            )
                                                        }
                                                        ) 
                                                    }

                                                    </OwlCarousel>
                                                   
                                        </div>                                      
                                    )
                                })
                        }
    </div>
    <CallToAction
    header={t("cta.header")}
    subheader={t("cta.subheader")}
    link="/contact"
    button={t("cta.button")}
    />
    
    </React.Fragment>
  )
}

export {Projects}
