import React from 'react'
import { ServiceContent } from '../Components/ServiceContent';
import { CallToAction } from '../Components/CallToAction';
import { TestimonalHome } from '../Components/TestimonalHome';
import { FormContact } from '../Components/FormContact';

function ServicesOld() {
  const services = [
    {
      title: "Consulting",
      description:
        "Gain high-level strategic guidance from our experts who will help you develop a sustainable growth plan and execute it efficiently. We'll help you understand your customer needs, business KPIs, and industry dynamics, allowing you to build an effective product that resonates with your target audience."
    },
    {
      title: "Product Discovery",
      description:
        "Our creative process focuses on understanding the needs and problems of your users or customers, so you can gain a deeper understanding of their issues and uncover adjacent and underlying needs that are important when making decisions about your product. We use the best practices of research to provide you with valuable insights that inform your product development."
    },
    {
      title: "Business Model Canvas",
      description:
        "We guide you through the Business Model Canvas, providing you with a precise roadmap for success. With our experienced team, you can identify opportunities and understand your business better. We use successful model patterns to ensure that your product is developed with precision, vision, and a success mindset."
    },
    {
      title: "Design & Digital product development",
      description:
        "Using agile methodologies, we develop high-quality digital products that align with your vision. We start by launching prototypes to quickly learn about user feedback and iterate for the best MVP launch. Our frequent check-ins ensure that every digital product we develop is of the highest quality."
    },
    {
      title: "UX/UI",
      description:
        "Our goal is to deliver products that not only look great but also provide an exceptional user experience for everyone using them. We create innovative and engaging digital products that resonate with your target audience."
    },
    {
      title: "Web & Landing page",
      description:
        "Our tailored web development services showcase your business and validate your ideas, business plans, or roadmaps, ensuring that your content and features are always up-to-date. Our SEO-friendly designs incorporate the latest trends in search engines, differentiating your business from the competition."
    },
    {
      title: "Marketing",
      description:
        "Maximize your ROI through our effective marketing and advertising campaigns. Our experienced team of marketers and strategists provides ideation, creation, planning, measurement, and analysis services. Whether you need traffic, leads, or conversions, we use the best practices to achieve your business goals."
    },
    {
      title: "Copy's & Content creation",
      description:
        "We create compelling, engaging content that resonates with your target audience. Our experienced copywriters work with you to develop a clear understanding of your goals and objectives, crafting informative, well-structured pieces that can be used across multiple platforms. Trust us to create dynamic content that drives leads and conversions for your business."
    },
    {
      title: "Support & Maintenance",
      description:
        "We offer ongoing support and maintenance to keep your website or digital product up and running smoothly. Our team of experts provides fast and reliable services to ensure that your business is always online and accessible to your customers."
    }
  ];
  return (
<React.Fragment>
    <div className="container-fluid py-5 mb-5 wow fadeIn" style={{backgroundColor:"#506af0"}} data-wow-delay="0.1s">
        <div className="container text-center py-5">
            <h2 className="display-2 text-white mb-4 animated slideInDown">Services</h2>
            <h3 className=" text-light" style={{marginTop: "0.5rem"}}>Your success is our top priority</h3>
        </div>
    </div>
    <section className=" py-5">
      <div className="container">
          {
            services.map(serviceContent=>{
              return(
                <ServiceContent
                  title={serviceContent.title}
                  description={serviceContent.description}
                />
            )})
          }      
      </div>
    </section>
    <CallToAction
      header="We help you transform your ideas into reality!"
      subheader="Take the next step to grow"
      link="/contact"
      button="Start Now!"
    />
    <TestimonalHome />
    <CallToAction
      header="From ideation to success"
      subheader="Let's join your experience with ours and create something great!"
      link="/contact"
      button="Contact us!"
    />
    <FormContact/>
</React.Fragment>
  )
}

export {ServicesOld}
