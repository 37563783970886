import React from 'react'

function CallToAction({header,subheader,button,link}) {
  return (
    <div className="container-fluid bg-primary" >
        <div className="d-flex flex-column p-5 align-items-center">
            <h2 className="text-light" >{header}</h2>
            <h5 className="text-white mt-1" >{subheader}</h5>
                <a href={link}><button className="btn offset mt-3 btn-lg" ><span className='mt-2'>{button}</span></button></a>
        </div>
      </div>
  )
}

export {CallToAction}
